<template>
  <page-view class="excellent-design" :up-class="'excellent-design'">
    <meta name="referrer" content="no-referrer">
    <div class="exc-content">
      <div class="title-box" ref="excRef">
        <div class="name case-name">{{ chooseDetail.title }}</div>
        <div class="details">
          <div class="left">
            <span>发布企业：<span class="com-name" @click="toComIndex()">{{ chooseDetail.companyName }}<i
                  class="el-icon-position"></i></span></span>
          </div>
          <div class="right">
            <i class="time">发布时间：{{ chooseDetail.updateDate?.split(" ")[0] }}</i>
            <i class="iconfont icon-liulan"></i><span>{{ +chooseDetail.hits }}</span>
            <!-- <i class="iconfont icon-shoucangxuanzhong" v-if="!isNews"></i><span v-if="!isNews">{{
                                +chooseDetail.collect
                        }}</span> -->
          </div>
        </div>
      </div>
      <div class="exc-box">
        <div class="exc-txt" v-html="chooseDetail.content"></div>
      </div>
      <div class="collection" v-if="!isNews">
        <!-- <div class="collection-btn" @click="collection = !collection" :class="{ active: collection }">
                    <i class="el-icon-star-off" :class="[collection ? 'el-icon-star-on' : 'el-icon-star-off']"></i><span
                        v-show="collection">已</span>收藏666
                </div> -->
      </div>
      <div class="prev-next">
        <!-- <el-tooltip
          :content="prevTitle"
          placement="bottom-end"
          effect="light"
        > -->
        <div class="article" @click="changePages('--')">
          <span class="btn">上一篇</span>
          <span :title="prevTitle">：{{ prevTitle }}</span>
          <img class="supimg" src="../assets/img/new_sup.svg" alt="" />
        </div>
        <!-- </el-tooltip> -->
        <!-- <el-tooltip
          :content="nextTitle"
          placement="bottom-end"
          effect="light"
        > -->
        <div class="article" @click="changePages('++')">
          <span class="btn">下一篇</span>
          <span :title="nextTitle">：{{ nextTitle }}</span>
          <img class="supimg" src="../assets/img/new_sup.svg" alt="" />
        </div>
        <!-- </el-tooltip> -->
      </div>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ExcellentDesign",
  data() {
    return {
      collection: false,
      pagesIndex: 0,
      chooseDetail: {},
    };
  },
  computed: {
    ...mapState(["theArticle", "theArticleList"]),
    isNews() {
      // return this.$route.query.isNews;
      return false;
    },
    prevTitle() {
      return this.pagesIndex <= 0
        ? "没有了"
        : this.theArticleList[this.pagesIndex - 1]?.title;
    },
    nextTitle() {
      return this.pagesIndex >= this.theArticleList.length - 1
        ? "没有了"
        : this.theArticleList[this.pagesIndex + 1]?.title;
    },
  },
  created() {
    this.getChooseDetail(this.theArticle.id);
    //获取当前文章索引
    this.pagesIndex = this.theArticleList.findIndex(
      (item) => this.theArticle.id == item.id
    );
  },
  methods: {
    ...mapActions(["setTheArticle"]),
    // 获取文章详情
    async getChooseDetail(id) {
      let result = await this.$api.getChooseDetail(id);
      this.chooseDetail = result.data?.article;
      //修改网页标题
      document.title = `${this.chooseDetail.companyName}-${this.chooseDetail.title}`;
    },
    //获取文章上下页
    changePages(prev) {
      if (prev === "--" && this.pagesIndex > 0) {
        this.pagesIndex--;
      } else if (
        prev === "++" &&
        this.pagesIndex < this.theArticleList.length - 1
      ) {
        this.pagesIndex++;
      } else {
        return false;
      }
      this.getChooseDetail(this.theArticleList[this.pagesIndex].id);
      this.setTheArticle(this.theArticleList[this.pagesIndex]);
      this.$refs.excRef.scrollIntoView({
        block: "center",
      });
    },
    toComIndex() {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid: this.chooseDetail.companyid },
      });
      window.open(openRoute.href, "_blank");
    }
  },
};
</script>
<style lang='scss' scoped>
.excellent-design {
  background-color: #f5f5f5;

  .exc-content {
    width: 70%;
    overflow: hidden;
    margin: 30px auto;
    margin-bottom: 100px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #fff;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .title-box {
      border-bottom: 1px solid #afafaf;
      padding-bottom: 20px;

      .name {
        font-size: 28px;
      }

      .case-name {
        text-align: center;
      }

      .details {
        @include flexbox(row, space-between, center);
        font-size: 16px;
        margin-top: 10px;

        @media screen and (max-width: 1200px) {
          @include flexbox(column);
        }
      }

      .left {
        .time {
          margin-left: 15px;
        }

        .com-name {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: $primaryColor;
          }
        }
      }

      .right {
        @include flexbox(row, flex-start, center);
        
        @media screen and (max-width: 1200px) {
          @include flexbox(row, flex-end, center);
          margin-top: 4px;
        }

        >i {
          margin-left: 10px;
          margin-right: 5px;
          font-size: 20px;
          color: #535353;
        }

        .time {
          font-size: 16px;
          color: #8a8a8a;
          margin-left: 12px;

          @media screen and (max-width: 1200px) {
            margin-left: 0;
          }
        }
      }
    }

    .exc-box {
      margin-top: 20px;
      min-height: 500px;

      .exc-name {
        color: #262626;
      }

      .exc-txt {
        color: #818181;
        text-indent: 2em;
        padding: 20px 0;

        @media screen and (max-width: 1200px) {
          font-size: 0.28rem;
        }

        :deep(img) {
          max-width: 94%;
          // height: auto;

          @media screen and (max-width: 1200px) {
            max-width: 100%;
            display: block;
            // height: auto;
          }
        }
      }
    }

    .collection {
      @include flexbox(row, center);
      margin: 20px 0;

      .collection-btn {
        background: linear-gradient(180deg,
            #439c4c 0%,
            rgba(105, 175, 112, 0.59) 93%);
        border-radius: 50px;
        color: #fff;
        font-size: 12px;
        padding: 12px 22px;
        cursor: pointer;

        i {
          font-size: 14px;
          margin-right: 2px;
        }

        &.active {
          background: #b7b5b5;

          i {
            color: #666464;
          }
        }
      }
    }

    .prev-next {
      font-size: 14px;
      @include flexbox(column, flex-start, flex-start);

      .article {
        margin-top: 20px;
        cursor: pointer;
        @include flexbox(row);

        &:hover {
          text-decoration: underline;
        }

        .btn {
          color: $primaryColor;
          white-space: nowrap;

          +span {
            // flex: 1;
            width: 60%;
            white-space: nowrap; //禁止换行
            overflow: hidden;
            text-overflow: ellipsis; //...
          }
        }

        .supimg {
          width: 26px;
          position: relative;
          bottom: 12px;
          right: 10px;
        }
      }
    }
  }
}
</style>